import { z } from 'zod'
import { EngagementOptionArray } from '@forgd/supabase'
import { stringToIntNumber } from '@forgd/shared/utils/zod'

export const GetKPIReportQuery = z.object({
  date: z.string().date(),
  marketMakerId: z.string().nullish(),
  daysLookback: z
    .string()
    .optional()
    .nullable()
    .transform(stringToIntNumber)
    .refine((val) => val == null || val > 0, {
      message: 'daysLookback must be a positive integer',
    }),
})

export type GetKPIReportQueryType = z.infer<typeof GetKPIReportQuery>

const StatusOptionArray = ['Lagging', 'On Track'] as const
export const GetKPIReportResult = z.array(
  z.object({
    bidAskAvgKpi: z.number().nullish(),
    bidAskSpreadUptime: z.number().nullish(),
    bidAskStatus: z.enum(StatusOptionArray),
    bidAskTarget: z.number().nullish(),
    dept50BpsAvgKpi: z.number().nullish(),
    dept50BpsStatus: z.enum(StatusOptionArray),
    dept50BpsTarget: z.number().nullish(),
    dept50BpsUptime: z.number().nullish(),
    depth100BpsAvgKpi: z.number().nullish(),
    depth100BpsStatus: z.enum(StatusOptionArray),
    depth100BpsTarget: z.number().nullish(),
    depth100BpsUptime: z.number().nullish(),
    depth200BpsAvgKpi: z.number().nullish(),
    depth200BpsStatus: z.enum(StatusOptionArray),
    depth200BpsTarget: z.number().nullish(),
    depth200BpsUptime: z.number().nullish(),
    exchange: z.string(),
    fillVolume: z.number().nullish(),
    marketMakerId: z.string(),
    projectId: z.string().uuid(),
    sourceId: z.string().uuid(),
    typeOfMM: z.enum(EngagementOptionArray),
    marketMaker: z.object({ id: z.string(), name: z.string() }),
  }),
)

export type GetKPIReportResultType = z.infer<typeof GetKPIReportResult>
