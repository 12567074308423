import { z } from 'zod'

enum ActiveRFQStatusFilterOptions {
  All = 'all',
  AcceptingQuotes = 'accepting_quotes',
  InReviewRFQs = 'in_review_rfqs',
  ParticipatingRFQs = 'participating_rfqs',
}

enum MMLeaderboardFilterByOptions {
  All = 'all',
  Depth = 'depth',
  Volume = 'volume',
  Spread = 'spread',
}

enum ClosedRFQStatusFilterOptions {
  All = 'all',
  ParticipatedAndSelected = 'participated_and_selected',
  ParticipatedAndRejected = 'participated_and_rejected',
  IgnoredRFQs = 'ignored',
}

enum RFQKPISummaryFilterOptions {
  AllReceivedQuotes = 'all_received_quotes',
  AllSelectedAverage = 'all_selected_average',
  MyQuotesAverage = 'my_quotes_average',
}

enum MMRFQParticipationStatuses {
  Selected = 1,
  NotSelected = 2,
  Ignored = 3,
}

export const MMLeaderBoardItem = z.object({
  marketMakerId: z.string(),
  marketMakerName: z.string(),
  imageUrl: z.string().optional().nullable(),
  weightedRank: z.number().optional(),
  depthRank: z.number().optional(),
  volumeRank: z.number().optional(),
  spreadRank: z.number().optional(),
  projectsTracked: z.number(),
  avgProjectFDV: z.number(),
  tier1: z.number(),
  tier2: z.number(),
  tier3: z.number(),
  avgDollarDepth: z.number().optional(),
  avgDepthOfTotal: z.number().optional(),
  avgDailyVolume: z.number().optional(),
  avgVolumeOfTotal: z.number().optional(),
  avgSpreadPercent: z.number().optional(),
})

export const MarketMakerLeaderboardResponse = z.object({
  all: z.array(MMLeaderBoardItem),
  byDepth: z.array(MMLeaderBoardItem),
  byVolume: z.array(MMLeaderBoardItem),
  bySpread: z.array(MMLeaderBoardItem),
})

export type MarketMakerLeaderboard = z.infer<typeof MarketMakerLeaderboardResponse>

export {
  ActiveRFQStatusFilterOptions,
  ClosedRFQStatusFilterOptions,
  RFQKPISummaryFilterOptions,
  MMLeaderboardFilterByOptions,
  MMRFQParticipationStatuses,
}
