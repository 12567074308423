import { z } from 'zod'
import { initContract } from '@ts-rest/core'
import {
  ExchangesResult,
  InvestorsResult,
  MarketMakerRFQBody,
  MarketMakersResult,
  QuotesByEngagementTypeResponse,
  QuotesSelectionPreviewResponse,
  RFQDetailsResult,
  RFQRoundDetailsResponse,
} from '../types'
import { ProjectPathParam } from '../types/common'

const c = initContract()

export const marketMakerRFQContract = c.router({
  getRFQDetails: {
    method: 'GET',
    path: `/market-maker-rfq/details/:projectId`,
    responses: {
      200: RFQDetailsResult,
    },
    pathParams: ProjectPathParam,
    summary: 'Get market maker rfq details.',
  },
  getInvestors: {
    method: 'GET',
    path: `/market-maker-rfq/investors`,
    responses: {
      200: InvestorsResult,
    },
    query: z.object({
      search: z.string().min(1),
    }),
    summary: 'Get investors.',
  },
  getExchanges: {
    method: 'GET',
    path: `/market-maker-rfq/exchanges`,
    responses: {
      200: ExchangesResult,
    },
    summary: 'Get exchanges.',
  },
  getMarketMakers: {
    method: 'GET',
    path: `/market-maker-rfq/market-makers`,
    responses: {
      200: MarketMakersResult,
    },
    summary: 'Get a list of all market makers.',
  },
  saveRFQ: {
    method: 'POST',
    path: `/market-maker-rfq/:projectId`,
    responses: {
      204: z.undefined().optional(),
    },
    body: MarketMakerRFQBody,
    pathParams: ProjectPathParam,
    summary: 'Save RFQ.',
  },
  getRoundDetails: {
    method: 'GET',
    path: `/market-maker-rfq/round/:projectId`,
    responses: {
      200: RFQRoundDetailsResponse,
    },
    query: z.object({
      round: z.coerce.number().int().min(1).max(2),
    }),
    pathParams: ProjectPathParam,
    summary: 'Get market maker rfq round details.',
  },
  getQuotesBreakdown: {
    method: 'GET',
    path: `/market-maker-rfq/round/:projectId/quotes`,
    responses: {
      200: z.object({
        quotes: QuotesByEngagementTypeResponse,
      }),
    },
    query: z.object({
      round: z.coerce.number().int().min(1).max(2),
    }),
    pathParams: ProjectPathParam,
    summary: 'Get quotes breakdown for the round.',
  },
  getQuotesSelectionPreview: {
    method: 'GET',
    path: `/market-maker-rfq/round/:projectId/quotes/selection`,
    responses: {
      200: QuotesSelectionPreviewResponse,
    },
    query: z.object({
      round: z.coerce.number().int().min(1).max(2),
      selectedQuoteIds: z.preprocess((value: unknown) => {
        if (Array.isArray(value)) {
          return value
        }
        if (typeof value === 'string' && value) {
          return [value]
        }
        return undefined
      }, z.array(z.string().uuid()).min(1).max(5).optional()),
    }),
    pathParams: ProjectPathParam,
    summary: 'Get selected quotes breakdown and comparison.',
  },
  requote: {
    method: 'POST',
    path: `/market-maker-rfq/requote/:projectId`,
    responses: {
      204: z.undefined(),
    },
    body: z.object({
      quoteIds: z
        .array(z.string().uuid())
        .min(1, 'Provide at least one quote to requote.')
        .max(5, 'You are able to requote for up to 5 quotes from the pool of proposals.'),
    }),
    pathParams: ProjectPathParam,
    summary: 'Requote quotes for the first round.',
  },
  accept: {
    method: 'POST',
    path: `/market-maker-rfq/accept/:projectId`,
    responses: {
      204: z.undefined(),
    },
    body: z.object({
      round: z.number().int().min(1).max(2),
      quoteIds: z
        .array(z.string().uuid())
        .min(1, 'Provide at least one quote to accept.')
        .max(5, 'You are able to accept for up to 5 quotes from the pool of proposals.'),
    }),
    pathParams: ProjectPathParam,
    summary: 'Accept quotes for the round.',
  },
  adminStatusUpdate: {
    method: 'POST',
    path: `/market-maker-rfq/admin/status-update/:projectId`,
    responses: {
      204: z.undefined(),
    },
    pathParams: ProjectPathParam,
    body: z.object({}),
    summary: 'Admin status update.',
  },
  quotesImported: {
    method: 'POST',
    path: `/market-maker-rfq/quotes-imported/:projectId`,
    responses: {
      204: z.undefined(),
    },
    pathParams: ProjectPathParam,
    body: z.object({
      round: z.number(),
    }),
    summary: 'Finalizes the import process after quotes have been successfully imported',
    metadata: {
      openApiSecurity: [], // public endpoint
    },
  },
})

export type MarketMakerRFQContractType = typeof marketMakerRFQContract
