import { z } from 'zod'
import { MetaSchema } from '../calculator'
import { PriceDataSchema, TokenInfoResult } from '../token-unlock'
import {
  ContactTypeEnum,
  EngagementOptionArray,
  EngagementOptionEnum,
  ExchangeTypeArray,
  MarketMakerQuoteKpiMetricArray,
  MarketMakerQuoteKpiMinMaxUnitArray,
  MarketMakerQuoteLoanCustodyArray,
  MarketMakerQuoteStatusArray,
  MarketMakerQuoteTranchePriceMethodologyArray,
  MarketMakerRequestStatusArray,
  OptionsStyleArray,
} from '@forgd/supabase'
import { MemberContactType } from '../membership'
import { sortBy } from 'lodash'

export const MMRFQTranchePriceFlagArray = ['discount', 'par', 'premium'] as const

export enum MMRFQTranchePriceFlagEnum {
  Discount = 'discount',
  Par = 'par',
  Premium = 'premium',
}

export interface MarketMakerRFQOption {
  value: string
  label: string
  description?: string
  loanRequirements?: string[]
}

export const OptionResult = z.object({
  value: z.string(),
  label: z.string(),
})

export type Investor = z.infer<typeof OptionResult>

export const InvestorsResult = z.array(OptionResult)

export const RFQProjectDetailsResult = z.object({
  id: z.string().uuid(),
  isResearch: z.boolean(),
  ticker: z.string().nullable(),
  tokenListed: z.boolean().nullable().default(false),
  link: z.string().nullable(),
  coingeckoId: z.string().nullable(),
  isTdPublished: z.boolean(),
  hasRFQ: z.boolean(),
  tgeDate: z.string().nullable(),
  image: z.string().nullable(),
  name: z.string().nullable(),
})
export type RFQProjectDetails = z.infer<typeof RFQProjectDetailsResult>

export const RFQTokenDesignerDetailsResult = z.object({
  maxTokenSupply: z.number().nullable(),
  fdvAtTGE: z.number().nullable(),
  tokenPriceAtTGE: z.number().nullable(),
  percentageUnlockedAtTGE: z.number(),
  circulatingSupply: z.number().nullable(),
  marketCapAtTGE: z.number().nullable(),
})

export type RFQTokenDesignerDetails = z.infer<typeof RFQTokenDesignerDetailsResult>

export const RFQTokenMarketDetailsResult = TokenInfoResult.omit({
  tgeDate: true,
}).merge(
  z.object({
    fdv: z.number(),
    circulatingSupply: z.number(),
    marketCap: z.number().nullable(),
    price: z.number(),
    priceChange24h: z.number(),
    marketChartPrice24h: z
      .object({
        data: z.array(PriceDataSchema),
        meta: MetaSchema,
      })
      .nullable(),
  }),
)

export type RFQTokenMarketDetails = z.infer<typeof RFQTokenMarketDetailsResult>

export const RFQStatusItem = z.object({
  status: z.enum(MarketMakerRequestStatusArray),
  date: z.string().nullable(),
})

export const RFQResult = z.object({
  status: z.enum(MarketMakerRequestStatusArray),
  statusUpdatedAt: z.string().nullable(),
  marketMakersQty: z.number().min(1).max(4),
  engagementOptions: z.array(z.enum(EngagementOptionArray)).min(1).nullable(),
  tokenSupplyPercentageToAllocate: z.string().min(1),
  stableCoinBudgetToAllocate: z.string().min(1),
  motivations: z.array(z.string()),
  exchangesCEX: z.array(z.string()),
  exchangesDEX: z.array(z.string()),
  externalCapital: z.string().nullable(),
  investors: z.array(z.string()).optional(),
  country: z.string().min(2),
  hasLegalOpinionOnUtilityStatus: z.boolean(),
})

export const RFQDetailsResult = z.object({
  project: RFQProjectDetailsResult,
  tokenDesignerDetails: RFQTokenDesignerDetailsResult.optional(),
  tokenMarketDetails: RFQTokenMarketDetailsResult.optional(),
  statuses: z.array(RFQStatusItem),
  rfq: RFQResult.nullable(),
})

export type RFQDetails = z.infer<typeof RFQDetailsResult>

export const ExchangeResult = OptionResult.extend({
  url: z.string().nullable().optional(),
  type: z.enum(ExchangeTypeArray),
})

export type Exchange = z.infer<typeof ExchangeResult>

export const ExchangesResult = z.array(ExchangeResult)

export const MarketMakerResult = z.object({
  name: z.string(),
  description: z.string().nullable(),
  imageUrl: z.string().nullable(),
  engagementOptions: z.array(z.enum(EngagementOptionArray)),
  supportedCEX: ExchangesResult,
  supportedDEX: ExchangesResult,
  services: z.array(z.string()),
})

export type MarketMaker = z.infer<typeof MarketMakerResult>

export const MarketMakersResult = z.array(MarketMakerResult)

export const MarketMakerRFQBody = z.object({
  // step 1
  country: z.string().min(2, 'Country is required'),
  isTokenIssuedInUSTerritory: z.boolean().nullable().optional(),
  hasLegalOpinionOnUtilityStatus: z.boolean({
    message: 'Legal opinion is required',
  }),
  externalCapital: z.string().min(1, 'The Amount of external capital is required'),
  investors: z.array(z.string().uuid()).optional(),
  // step 2
  motivations: z.array(z.string()).min(1),
  exchangesCEX: z.array(z.string()),
  exchangesDEX: z.array(z.string()),
  otherCEX: z.array(z.string()),
  otherDEX: z.array(z.string()),
  // step 3
  marketMakersQty: z.number().min(1).max(4),
  contactType: z.nativeEnum(MemberContactType),
  contact: z.string().min(1, 'Contact is required'),
  engagementOptions: z.array(z.enum(EngagementOptionArray)).min(1),
  tokenSupplyPercentageToAllocate: z.string().min(1),
  stableCoinBudgetToAllocate: z.string().min(1),
  otherMarketMakers: z.array(z.string()),

  calendarLink: z.string().optional(),
})

export type MarketMakerRFQ = z.infer<typeof MarketMakerRFQBody>

export type MMRFQEmailRequest = {
  projectId: string
  projectName: string
  projectTicker: string
  projectLink: string
  tokenListed: boolean
  tgeDate: string
  coingeckoId: string | null
  country: string
  isUSA: boolean
  isUtility: boolean
  externalCapital: string
  investors: string[]
  exchangesCEX: string[]
  exchangesDEX: string[]
  motivations: string[]
  marketMakersQty: number
  engagementOptions: EngagementOptionEnum[]
  tokenSupplyPercentageToAllocate: string
  stableCoinBudgetToAllocate: string
  otherMarketMakers: string[]
  contactType: ContactTypeEnum
  contact: string
  calendarLink: string
}

export type MMRFQRoundInReviewEmailRequest = {
  round: number
  projectName: string
  projectTicker: string | null
  mmQty: number
  quotesQty: number
  dateToReply: Date
}

export type MMRFQAcceptQuotesEmailRequest = {
  projectName: string
  round: number
  selection: string[]
}

export type MMRFQRequoteQuotesEmailRequest = {
  projectName: string
  selection: string[]
  date: Date
}

const QuoteKPISchema = z.object({
  quoteId: z.string(),
  metric: z.string(),
  valueTier1Exchanges: z.number(),
  valueTier2Exchanges: z.number(),
  valueTier3Exchanges: z.number(),
  minValue: z.number(),
  maxValue: z.number(),
  minMaxUnit: z.string(),
})

const QuoteDMMSchema = z.object({
  quoteId: z.string(),
  onboardingFeeUsd: z.number(),
  unlimitedExchangeCoverage: z.boolean(),
  unlimitedExchangeCoverageMonthlyFeeUsd: z.number(),
  singleExchangeCoverageMonthlyFeeUsd: z.number(),
  minContractTermMonths: z.number(),
  profitSharePercentage: z.number(),
  loanCustody: z.enum(MarketMakerQuoteLoanCustodyArray),
})

const QuoteTrancheSchema = z.object({
  quoteId: z.string(),
  trancheNumber: z.number(),
  tokenSupplyPercentage: z.number(),
  priceValue: z.number(),
  priceMethodology: z.enum(MarketMakerQuoteTranchePriceMethodologyArray),
  twapThresholdDays: z.number().nullable(),
  timePeriod: z.number(),
})

export const QuoteSchema = z.object({
  id: z.string(),
  rfqId: z.string(),
  projectId: z.string(),
  marketMakerId: z.string(),
  round: z.number(),
  proposal: z.number().nullable(),
  engagementType: z.enum(EngagementOptionArray),
  loanTokenSupplyPercentage: z.number(),
  loanStablecoinQuantity: z.number(),
  loanOptionsStyle: z.enum(OptionsStyleArray).nullable(),
  loanTenorMonths: z.number().nullable(),
  loanInterestRatePercentage: z.number().nullable(),
  kpiOrderBookDominanceThreshold: z.number(),
  status: z.string(),
  selectedForRequote: z.boolean(),
  createdAt: z.string(),
  dmm: z.union([QuoteDMMSchema.nullable(), z.array(QuoteDMMSchema).optional()]),
  kpis: z.array(QuoteKPISchema),
  tranches: z.array(QuoteTrancheSchema).optional(),
})

export type Quote = z.infer<typeof QuoteSchema>

export const ViewSchemaResponse = z.object({
  value: z.number(),
  unit: z.string(),
})

export const MetricSchemaResponse = z.object({
  metric: z.enum(MarketMakerQuoteKpiMetricArray),
  views: z.object({
    percent: ViewSchemaResponse,
    usd: ViewSchemaResponse,
  }),
})

export const TierSchemaResponse = z.object({
  tier: z.string(),
  data: z.array(MetricSchemaResponse),
})

export type TierSchema = z.infer<typeof TierSchemaResponse>
export const RFQQuotesSummary = z.object({
  quotesReceptionDate: z.string().date().optional(),
  quotesCount: z.number(),
  engagements: z.object({
    [EngagementOptionEnum.LoanCallOption]: z.object({
      quotesCount: z.number(),
      avgTokenLoanSize: z.number(),
      avgTokenLoanSupplyPercentage: z.number(),
      europeanOptionsCount: z.number(),
      americanOptionsCount: z.number(),
      avgLoanTenorMonths: z.number(),
      avgTokenLoanSizeUsd: z.number(),
      avgOptionPremiumPerQuote: z.number(),
      avgInterestRatePercentage: z.number(),
      avgNumberOfTranches: z.number(),
      tranchesFixedPricePercentage: z.number(),
      tranchesDynamicPricePercentage: z.number(),
      lessThan1MonthPercentage: z.number(),
      between1And6MonthsPercentage: z.number(),
      greaterThan6MonthsPercentage: z.number(),
      avgOrderBookDominanceMax: z.number(),
    }),
    [EngagementOptionEnum.RetainerWorkingCapital]: z.object({
      quotesCount: z.number(),
      avgTokenLoanSize: z.number(),
      avgTokenLoanSupplyPercentage: z.number(),
      avgStablecoinLoanSizeUsd: z.number(),
      avgTokenLoanSizeUsd: z.number(),
      avgOnboardingFeeUsd: z.number(),
      avgMonthlyFeeUsd: z.number(),
      avgProfitSharePercent: z.number(),
      avgTotalLoanSizeUsd: z.number(),
      quotesWithUnlimitedExchangeCoveragePercantage: z.number(),
      quotesWithFeesChargedPerExchangePercantage: z.number(),
    }),
  }),
})

export type RFQQuotesSummary = z.infer<typeof RFQQuotesSummary>

export const RFQRoundDetailsResponse = z.object({
  quotesSummary: RFQQuotesSummary,
  kpisSummary: z.array(TierSchemaResponse),
})

export type RFQRoundDetails = z.infer<typeof RFQRoundDetailsResponse>

export const LoanCallOptionTranche = z.object({
  trancheNumber: z.number().int().min(0).finite(),
  tokenSupplyPercentage: z.number(),
  priceMethodology: z.enum(MarketMakerQuoteTranchePriceMethodologyArray),
  price: z.number(),
  priceFlag: z.enum(MMRFQTranchePriceFlagArray),
  twapThresholdDays: z.number().nullable(),
  timePeriod: z.number(),
})

export type LoanCallOptionTranche = z.infer<typeof LoanCallOptionTranche>

const BaseQuoteSchema = z.object({
  id: z.string().uuid(),
  status: z.enum(MarketMakerQuoteStatusArray),
  engagementType: z.string(),
  marketMakerName: z.string(),
  marketMakerImageUrl: z.string().nullable(),
  proposal: z.number().int().min(1).finite().nullable(),
  loanTokenSupplyPercentage: z.number(),
  loanTokenQuantity: z.number(),
  loanTokenSizeUsd: z.number(),
  kpiOrderBookDominanceThreshold: z.number(),
  kpiLoanValueDepth200BpsUsd: z.number(),
  projectedLoanUtilizationPercentage: z.number(),
  kpis: z.array(TierSchemaResponse),
  marketMakerComment: z.string().nullable(),
})

export const LoanCallOptionQuoteResponse = BaseQuoteSchema.extend({
  loanOptionsStyle: z.enum(OptionsStyleArray),
  loanTenorMonths: z.number(),
  loanInterestRatePercentage: z.number().nullable(),
  kpiBidAskSpreadBps: z.number(),
  tranchesCount: z.number(),
  tranches: z.array(LoanCallOptionTranche),
})

export type LoanCallOptionQuote = z.infer<typeof LoanCallOptionQuoteResponse>

export const RetainerWorkingCapitalQuoteResponse = BaseQuoteSchema.extend({
  loanStablecoinSizeUsd: z.number(),
  loanCustody: z.enum(MarketMakerQuoteLoanCustodyArray),
  onboardingFeeUsd: z.number(),
  unlimitedExchangeCoverage: z.boolean(),
  monthlyFeeUsd: z.number(),
  minContractTermMonths: z.number(),
  profitSharePercentage: z.number(),
})

export type RetainerWorkingCapitalQuote = z.infer<typeof RetainerWorkingCapitalQuoteResponse>

export const QuotesByEngagementTypeResponse = z.object({
  [EngagementOptionEnum.LoanCallOption]: z.array(LoanCallOptionQuoteResponse),
  [EngagementOptionEnum.RetainerWorkingCapital]: z.array(RetainerWorkingCapitalQuoteResponse),
})

export type QuotesByEngagementType = z.infer<typeof QuotesByEngagementTypeResponse>

export const QuotesSelectionPreviewResponse = z.object({
  quotes: QuotesByEngagementTypeResponse,
  kpisSummary: z.array(TierSchemaResponse),
  quotesSummary: RFQQuotesSummary,
  selectedQuotesSummary: RFQQuotesSummary,
})

export type QuotesSelectionPreview = z.infer<typeof QuotesSelectionPreviewResponse>

const QuoteBaseSchema = z.object({
  kpiOrderBookDominanceThreshold: z.number().nonnegative().finite(),
  marketMakerComment: z.string().nullable(),
  kpis: z
    .array(
      z.object({
        metric: z.enum(MarketMakerQuoteKpiMetricArray),
        valueTier1Exchanges: z.number().nonnegative().finite(),
        valueTier2Exchanges: z.number().nonnegative().finite(),
        valueTier3Exchanges: z.number().nonnegative().finite(),
        minValue: z.number().nonnegative().finite(),
        maxValue: z.number().nonnegative().finite(),
        minMaxUnit: z.enum(MarketMakerQuoteKpiMinMaxUnitArray),
      }),
    )
    .length(MarketMakerQuoteKpiMetricArray.length)
    .refine((kpis) => new Set(kpis.map((item) => item.metric)).size === kpis.length, {
      message: 'KPI metrics must be unique',
    }),
})
export const QuoteLoanCallOptionSchema = QuoteBaseSchema.extend({
  engagementType: z.literal(EngagementOptionEnum.LoanCallOption),
  loanTokenSupplyPercentage: z.number().nonnegative().finite(),
  loanOptionsStyle: z.enum(OptionsStyleArray),
  loanTenorMonths: z.number().int().nonnegative().finite(),
  loanInterestRatePercentage: z.number().nonnegative().finite(),
  tranches: z
    .array(
      z.object({
        trancheNumber: z.number().int().min(1).max(4),
        tokenSupplyPercentage: z.number().nonnegative().finite(),
        priceMethodology: z.enum(MarketMakerQuoteTranchePriceMethodologyArray),
        priceValue: z.number().nonnegative().finite(),
        twapThresholdDays: z.number().int().nonnegative().finite().nullable(),
        timePeriod: z.number().int().nonnegative().finite(),
      }),
    )
    .min(1)
    .max(4)
    .refine(
      (tranches) => sortBy(tranches, 'trancheNumber').every((tranche, index) => tranche.trancheNumber === index + 1),
      { message: 'Tranches numbers must be sequential and start from 1' },
    ),
})
export const QuoteRetainerWorkingCapitalSchema = QuoteBaseSchema.extend({
  engagementType: z.literal(EngagementOptionEnum.RetainerWorkingCapital),
  loanStablecoinQuantity: z.number().nonnegative().finite(),
  dmm: z.object({
    onboardingFeeUsd: z.number().nonnegative().finite(),
    unlimitedExchangeCoverage: z.boolean(),
    unlimitedExchangeCoverageMonthlyFeeUsd: z.number().nonnegative().finite(),
    singleExchangeCoverageMonthlyFeeUsd: z.number().nonnegative().finite(),
    minContractTermMonths: z.number().int().nonnegative().finite(),
    profitSharePercentage: z.number().nonnegative().finite(),
    loanCustody: z.enum(MarketMakerQuoteLoanCustodyArray),
  }),
})
export const QuoteCreateSchema = z.discriminatedUnion('engagementType', [
  QuoteLoanCallOptionSchema,
  QuoteRetainerWorkingCapitalSchema,
])
export type QuoteCreateSchemaType = z.infer<typeof QuoteCreateSchema>
